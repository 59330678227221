import React from "react";
import CloudMenu from "./clouds_partners";

import Partenaire_1 from "../../../images/logos/partenaires_fournisseurs/logo_carel.png";
import Partenaire_2 from "../../../images/logos/partenaires_fournisseurs/logo_danfoss.png";
import Partenaire_3 from "../../../images/logos/partenaires_fournisseurs/logo_siemens.png";
import Partenaire_4 from "../../../images/logos/partenaires_fournisseurs/logo_tridium.png";
import Partenaire_5 from "../../../images/logos/partenaires_fournisseurs/Logo-Enless.png";
import Partenaire_6 from "../../../images/logos/partenaires_fournisseurs/logo_isma.png";
import Partenaire_7 from "../../../images/logos/partenaires_fournisseurs/logo_phoenixcontact.png";
import Partenaire_8 from "../../../images/logos/partenaires_fournisseurs/logo_wago.png";
import Partenaire_9 from "../../../images/logos/partenaires_fournisseurs/socomec_logo.png";
import Partenaires_10 from "../../../images/logos/clients/logo_spie.png";
import Partenaires_11 from "../../../images/logos/clients/logo_auchan.png";
import Partenaires_12 from "../../../images/logos/clients/logo_aushopping.png";
import Partenaires_13 from "../../../images/logos/clients/logo_total.png";
import Partenaires_14 from "../../../images/logos/clients/logo_vinci.png";
import Partenaires_15 from "../../../images/logos/clients/logo_snef.jpg";
import Partenaires_16 from "../../../images/logos/clients/logo_casino.png";
import Partenaires_17 from "../../../images/logos/partenaires_fournisseurs/logo_btib.png";
import Partenaires_18 from "../../../images/logos/partenaires_fournisseurs/logo_codra.png";

class LoadPartenaires {
    0 = Partenaire_1;
    1 = Partenaire_2;
    2 = Partenaire_3;
    3 = Partenaire_4;
    4 = Partenaire_5;
    5 = Partenaire_6;
    6 = Partenaire_7;
    7 = Partenaire_8;
    8 = Partenaire_9;
    9 = Partenaires_10;
    10 = Partenaires_11;
    11 = Partenaires_12;
    12 = Partenaires_13;
    13 = Partenaires_14;
    14 = Partenaires_15;
    15 = Partenaires_16;
    16 = Partenaires_17;
    17 = Partenaires_18;
}

export default class SkyColab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MenuIsActive: this.props.MenuIsActive,
        }
        this.createCloudsPartenaires(18);
    }
    
    cloudsPartenaires = [];
    urlPartenaires = ["https://www.carelfrance.fr/", "https://www.danfoss.com/fr-fr/",
    "https://www.siemens-home.bsh-group.com/fr/", "https://www.tridium.com/fr/fr/home", "https://enless-wireless.com/fr/",
    "https://www.ismacontrolli.com/en/", "https://www.phoenixcontact.com/fr-fr/", "https://www.wago.com/global/", "https://www.socomec.fr/fr",
    "https://www.spie.com/fr", "https://www.auchan.fr/", "https://www.aushopping.com/#/",
        "https://services.totalenergies.fr/", "https://www.vinci.com/", "https://www.snef.fr/", "https://www.groupe-casino.fr/", "https://www.btib.fr/", "https://www.codra.net"];

    createCloudsPartenaires(number) {
        var Partenaires = new LoadPartenaires();

        for (let i = 0; i < number; i++) {
            let animationDelay = Math.floor(Math.random()*1000)+"ms";
            this.cloudsPartenaires.push(
                    <CloudMenu
                        key={i}
                        width="60px"
                        height="60px"
                        Image={Partenaires[i]}
                        idImage={"img-Partenaires-" + i}
                        idClouds={"Clouds-n°" + i}
                        urlClouds={this.urlPartenaires[i]}
                        animDelay={animationDelay}
                    />
                )
        }
        return this.cloudsPartenaires;
    }

    render() {
        return (
            <div className={this.props.MenuIsActive ? "containerColab active" : "containerColab"}>
                    {this.cloudsPartenaires}
            </div>
        )
    }
}