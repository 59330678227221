import React from 'react';
import "../content.css";

export default function ContactUs()
{
    return (
        <div className="formbox">
            <h1 id="c">NOUS SITUER</h1>
            <p id="j">
                La société 
                <strong> Elsys Solutions </strong>
                est située à 
                <strong> Lunel-Viel </strong>
                et est également implantée dans plusieurs grandes villes afin de proposer ses services de développement de 
                <strong> GTC </strong>
                et
                <strong> GTB </strong>
                dans toute<strong> la France. </strong> 
                Nous travaillons aussi à l'international 
                <strong> (Thaïlande, Sénégal, Madagascar, l'île de la Réunion, l'île Maurice). </strong>
        
            </p>
    
            <h3 id="c">
                Contactez-nous pour vos projets en France ou à l'étranger.
            </h3>
        </div>
    )
}
