import React from "react";
import "./Ground.css";

export default class Ground extends React.Component {
    constructor(props)
    {
        super(props);
        this.state =
        {
            groundColor : this.groundColor,
            dirtColor : this.dirtColor,
            time: this.props.time,
        }
    }

    colorManagerInterval = 0;
    groundColor = "rgb(121,139,7)";
    dirtColor = "rgb(64,27,27)";

    componentDidMount()
    {
        // this.colorManagerInterval = setInterval(this.colorManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.colorManagerInterval);
    }

    colorManager = () =>
    {
        let t = this.props.time();

        if (t < 800 || t > 1800)
        {
            this.setState({groundColor : "black"});
            this.setState({dirtColor : "black"});
        }
        else
        {
            this.setState({groundColor : this.groundColor});
            this.setState({dirtColor: this.dirtColor});
        }
    }
    render() {
        return(
            <div className="split-ground filtBright">
                <div className="ground" style={{background : this.state.groundColor}}></div>
                <div className="dirt" style={{background : this.state.dirtColor}}></div>
            </div>
        )
    }
}