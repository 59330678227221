import React from "react";
import "./content.css";
import hill from "../../images/hill.svg";
import team from "../../images/elsys-team.svg";
import work from "../../images/elsys-work.png";

export default class Qui extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            isActive: this.props.isActive,
        }

    }

    render() {
        return (
            <div className="qui content" style={{ display: this.props.isActive ? "flex" : "none" }}>
                <div className="elsys box">
                    <h1 id="c">Elsys Solutions</h1>
                    <p id="j">
                        <b>Elsys Solutions</b> est spécialisée dans la conception, la maintenance et la supervision de systèmes automatisés.
                        Notre entreprise dispose d'une solide expérience dans <b>l'étude, le développement et la mise en service</b> de Gestion Technique du Bâtiment (GTB),
                        de Gestion Technique Centralisée (GTC) de régulateurs et d'automates destinés au pilotage de nombreux équipements (centrale de
                        traitement d'air, centrales frigorifiques, groupes d'eau glacée et bien d'autres…)
                        permettant <b>l'économie d'énergie et une gestion durable de la consommation</b>.

                        <br></br>
                        <br></br>

                        <b>Elsys Solutions</b> est une filiale d'Elsys,
                        créée en 2014 afin de spécialiser une partie des activités du groupe
                        autour de la gestion technique des bâtiments. Grâce à l'<b>expérience</b> de
                        la maison-mère et de ses nombreux partenaires à travers le monde,
                        Elsys Solutions bénéficie d'un <b>solide réseau de fournisseurs</b> et
                        une excellente connaissance des <b>processus métier</b>, permettant la proposition
                        d'une <b>offre personnalisée adaptée au mieux à vos besoins et vos attentes</b>.
                    </p>
                    <div className="quiBoxImageContainer">
                        <img src={hill} alt=""/>
                    </div>
                </div>
                <div className="avantages box">
                    <h1 id="c">Nos avantages</h1>
                    <p id="j">
                        De par sa <b>grande expérience</b> (plus de 1000 sites) et sa <b>connaissance des processus métier</b>,
                        Elsys Solutions possède les moyens de vous conseiller au mieux en fonction de vos ressources, disponibilités,
                        plannings, et s'attache à trouver la <b>solution qui vous convient</b>, au travers de programmes de régulation
                        <b> standardisés ou personnalisés</b>, pour répondre au mieux à vos contraintes techniques.
                        <br></br>
                        <br></br>
                        Un <b>chargé d'affaires</b> vous accompagnera <b>tout au long</b> de votre projet et
                        au-delà, afin de satisfaire à <b>vos demandes, d'assurer un suivi et une maintenance</b> tout en
                        s'adaptant <b>rapidement</b> à votre situation ou votre problématique. Une <b>équipe de développeurs</b> dédiée se charge de programmer votre site, et
                        une équipe de techniciens mobiles permet des interventions rapides dans toute
                        la France (et même au-delà) pour la <b>mise en
                            service, la maintenance, la résolution de problèmes et la gestion des incidents</b>.
                    </p>
                    <div className="quiBoxImageContainer">
                        <img src={team} alt="Equipe d'Elsys, intégrateur GTB GTC" />
                    </div>
                </div>
                <div className="processus box">
                    <h1 id="c">Notre processus</h1>
                    <p id="j">
                        Afin de choisir la solution technique <b>la plus adaptée</b>, <b>Elsys Solutions</b> utilise des critères de choix et de
                        pondérations tenant compte du cahier des charges, des contraintes matérielles, humaines, économiques et temporelles.
                        Les solutions techniques proposées sont <b>variées</b> et doivent être choisies et validées par le client. Nos propositions
                        sont toujours faites en tenant compte des critères de facilité,
                        rapidité de réalisation, efficacité et fiabilité, esthétique et design, durabilité et coût de la solution.
                    </p>
                    <div className="imgCont" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "60%" }}>
                        <img src={work} alt="Processus de création de GTB GTC avec client" style={{ width: "50%" }}></img>
                    </div>
                </div>

            </div>
        )
    }
}