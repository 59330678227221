import React from "react";
import './roofTop.css';


export default class Rooftop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: this.props.time,
            ventColor : "black",
            circleColor : "black",
            fanColor : "black",
            ledH : "led ledHot",
            ledC : "led ledCold",
            circle : "circle",
        }
    }

    render() {

        return (
            <div className="RoofVent" style={{
                bottom: this.props.bottom,
            }}>
                <div className="RoofVent-leds">
                    <div className={this.state.ledH}>
                    </div>
                    <div className={this.state.ledC}>
                    </div>
                </div>
                <div className="Roofvent-container">
                    <div className="vent" style = {{backgroundColor : this.state.ventColor}}>
                        <div className={this.state.circle} style = {{backgroundColor : this.state.circleColor}}>
                            <div className="ceiling-fan" style = {{backgroundColor : this.state.fanColor}}>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}