import React from "react";

export default class BuildingWindow extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            color : this.props.color,
            time : this.props.time,
            transitionDelay : 0,
            transitionDuration : "4s",
            id : this.props.id,
        }
    }

    chosen = false;
    windowTime = 0;
    sunColor = "rgba(255, 0, 0, 0.1)";
    dayColor = "rgba(255, 255, 255, 0.2)";
    randomColor = {
        red: Math.floor(Math.random()*255),
        green: Math.floor(Math.random()*255),
        blue: Math.floor(Math.random()*255)
    };
    actualColor = "rgb("+this.randomColor.red+","+this.randomColor.green+","+this.randomColor.blue+")";


    componentDidMount()
    {
        this.isChosen();
        // this.windowTime = setInterval(this.getColor, 2000);

    }

    isChosen()
    {
        let r = Math.floor(Math.random()*100);
        if (r > 90)
        {
            this.chosen = true;
        }
    }

    getColor = () =>
    {
        let t = this.state.time();
        if (t < 600 || t > 1900)
        {
            if (!this.chosen)
                this.setState({color : "black"});
            else
            {
                this.setState({color : "black"});
                let t = Math.floor(Math.random()*5000)+"ms";
                this.setState({color: this.actualColor});
                this.setState({transitionDelay : t});
                this.setState({transitionDuration : 0+"s"});
            }
            
        }
        if (t === 500)
        {
            this.setState({color : this.sunColor});

        }

        if (t === 700 && this.chosen)
        {
            this.setState({transitionDelay : "0s"});
            this.setState({transitionDuration : "10s"});
        }

        if (t === 900)
        {
            this.setState({color : this.dayColor});
        }
        if (t === 1900)
        {
            this.setState({color : this.sunColor});
        }
    }

    render()
    {
        return(
            <div className = {"buildingWindow bw"+this.state.id} style = {{
                backgroundColor : this.state.color,
                transitionDelay : this.state.transitionDelay,
                transitionDuration : this.state.transitionDuration,
            }}>

            </div>
        )
    }
}