import React from "react"
import ContactUs from "./contact/ContactUs";
// import Form from "./contact/Form";
import Locate from "./contact/Locate";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: this.props.isActive,
        }
    }

    render() {
        return (
            <div className= "contentform" style ={{display : this.props.isActive ? "flex" : "none"}
            }>
                <ContactUs/>
                <Locate/>
                {/* <Form/> */}
            </div>
        )
    }
}