import React from "react";
import "./Background.css";
import Hills from "./Hills";
import NightSky from "./nightSky";

export default class Background extends React.Component
{

    render()
    {
        return(
            <>
            <div className="gradient-bg">
            </div>
                <NightSky/>
            <div className="bg-hills">
                <Hills/>
            </div>
            </>
        )
    }
}