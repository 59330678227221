import React from "react";
import "./ElsysOffice.css";
import Y from "./y";

export default class ElsysOffice extends React.Component {
    constructor(props) {
        super(props);
        this.state = 
        {
            time : this.props.time,
            color : "grey",
        }
    }


    render() {

        return(
            <div className="elsysArea">
                    <Y id = "ieh" animDelay = {"0ms"} transform = "scale(3)"/>
                <div className="elsysOffice filtBright">
                </div>
            </div>
        )
    }
}