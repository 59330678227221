import React from "react";
import Hill from "./Hill";

export default class Hills extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            time : this.props.time,
        }
        this.fillColorArray();
        this.fillPositionArray();
    }

    colorArray = [];
    positionArray = [];

    fillPositionArray = () =>
    {
        for (let i = 0; i < 5; i++)
        {
            this.positionArray.push(Math.floor(Math.random()*100+200));
        }
    }

    fillColorArray = () =>
    {
        for (let i =0; i< 5; i++)
        {
            this.colorArray.push(this.getColor());
        } 
    }

    getColor = () =>
    {
        let r = Math.floor(Math.random()*150);
        let g = 125;
        let b = Math.floor(Math.random()*150);
        let col = "rgb("+r+","+g+","+b+")";
        // console.log("col", col);

        return col;
    }

    render()
    {
        return(
            <svg width="100%" height="100%">

                <Hill time={this.state.time} color={this.colorArray[0]} height={this.positionArray[0]} left={"0%"}/>
                <Hill time={this.state.time} color={this.colorArray[1]} height={this.positionArray[1]} left={"25%"}/>
                <Hill time={this.state.time} color={this.colorArray[2]} height={this.positionArray[2]} left={"50%"}/>
                <Hill time={this.state.time} color={this.colorArray[3]} height={this.positionArray[3]} left={"75%"}/>
                <Hill time={this.state.time} color={this.colorArray[4]} height={this.positionArray[4]} left={"100%"}/>
                    
             </svg>
        )
    }
}