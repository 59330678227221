import React from "react";

export default class BuildingPart extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            color : this.savedColor,
            time : this.props.time,
            transition : "filter 2s",
        }
    
    }

    colorManagerInterval = 0;
    savedColor = "rgb("+this.props.color+","+this.props.color+","+255+")";;

    componentDidMount()
    {
        // this.colorManagerInterval = setInterval(this.colorManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.colorManagerInterval);
    }

    colorManager = () =>
    {
        let t = this.props.time();

        if (t < 800 || t > 1800)
        {
            this.setState({color : "black"})
        }
        else
        {
            this.setState({transition : "background-color 5s"});
            this.setState({color : this.savedColor});
        }
    }

    render()
    {
        return(
            <div className = "buildPart filtBright" style = 
            {{
                backgroundColor : this.state.color,
                transition : this.state.transition,
            }}>

            </div>
        )
    }
}