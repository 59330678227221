import React from "react";

export default class Lamp extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            time : this.props.time,
            boxShadow : "0px 0px 75px 75px orange",
        }
    }

    lightInterval = 0;

    componentDidMount()
    {
        // this.lightInterval = setInterval(this.lightManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.lightInterval);
    }

    lightManager = () =>
    {
        if (this.state.time() < 600 || this.state.time() > 1700)
        {
            this.setState({boxShadow : "0px 0px 75px 75px orange"});
        }
        else
            this.setState({boxShadow : "none"});
    }

    render()
    {
        return(
            <div className="lamp" style={{
                boxShadow : this.state.boxShadow,

            }}></div>
        )
    }
}