import React from "react";
import './Partners.css';
import getRandomInt from "../../../script/Random";

export default class CloudMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            width: this.props.width,
            height: this.props.height,
            top: this.props.top,
            left: this.props.left,
            transform: this.props.transform,
            Image: this.props.Image,
            idImage: this.props.idImage,
            idClouds: this.props.idClouds,
            urlClouds: this.props.urlClouds,
            animDelay: this.props.animDelay,
        }
    }

    animDuration = getRandomInt(30, 100);
    animDelay = getRandomInt(0, 10);

    render() {
        return (
            <div className="container-cloud-menu" id={this.props.idClouds}>
                <div className={"cloud-menu floating"} style={{
                    width: this.state.width,
                    height: this.state.height,
                    top: this.state.top,
                    left: this.state.left,
                    animationDelay: this.state.animDelay,
                    animationDuration: this.animDuration,
                    transform: this.state.transform,
                }}>
                </div>
                <a className="floating" href={this.props.urlClouds} target="_blank" rel="noopener noreferrer" style={{animationDelay : this.state.animDelay}}>
                    <img id={this.props.idImage} src={this.props.Image} alt="" />
                </a>
            </div>
        )
    }
}
