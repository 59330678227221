import React from "react";
import "./Elsys.css";
import Header from "./components/header/Header";
import Ground from "./components/environment/ground/Ground";
import Sky from "./components/environment/clouds/Sky";
import SunMoon from "./components/environment/sun_moon/SunMoon";
import City from "./components/build/buildings/City";
import Forest from "./components/environment/tree/Forest";
import Background from "./components/environment/bg/Background";
import GTB from "./components/content/GTB";
import Qui from "./components/content/Qui";
import Produits from "./components/content/Produits";
import SkyColab from "./components/content/partners/Partners";
import ElsysOffice from "./components/build/buildings/elsys/ElsysOffice";
import Contact from "./components/content/Contact";
import $ from "jquery";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      width: 0,
      height: 0,
      time: 600,
      GTBUp: false,
      quiUp: false,
      prodUp: false,
      procUp : false,
      partUp: false,
      contUp: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    window.location.hash = "home";

  }

  oldValue = 0;
  PosScroll = 0;
  timer = 0;
  astraRotation = 6/24;
  windowsTreated = false;
  firstLoop = true;
  hashListenerInterval = 0;
  windowBlur = 0;
  windowFocus = 0;


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.timer = setInterval(this.update, 2000);
    this.hashListenerInterval = setInterval(this.hashListener, 400);
    $(window).on("blur", this.clearTimers);
    $(window).on("focus", this.startTimers);
  }

  clearTimers = () =>
  {
    clearInterval(this.timer);
    clearInterval(this.hashListenerInterval);
  }

  startTimers = () =>
  {
    clearInterval(this.timer);
    clearInterval(this.hashListenerInterval);
    this.timer = setInterval(this.update, 2000);
    this.hashListenerInterval = setInterval(this.hashListener, 400);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    clearInterval(this.timer);
    clearInterval(this.hashListenerInterval);
  }

  hashListener = () =>
  {
    var h = window.location.hash;

    switch (h) 
      {
        case "#gtb":
          window.location.hash = "gtb";
          this.setState({ GTBUp: true });
          this.setState({ quiUp: false });
          this.setState({ prodUp: false });
          this.setState({ procUp: false });
          this.setState({ partUp: false });
          this.setState({ contUp: false });
          break;
  
        case "#qui":
          window.location.hash = "qui";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: true });
          this.setState({ prodUp: false });
          this.setState({ procUp: false });
          this.setState({ partUp: false });
          this.setState({ contUp: false });
          break;
  
        case "#prod":
          window.location.hash = "prod";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: false });
          this.setState({ prodUp: true });
          this.setState({ procUp: false });
          this.setState({ partUp: false });
          this.setState({ contUp: false });
          break;
  
        case "#part":
          window.location.hash = "part";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: false });
          this.setState({ prodUp: false });
          this.setState({ procUp: false });
          this.setState({ partUp: true });
          this.setState({ contUp: false });
          break;
  
        case "#cont":
          window.location.hash = "cont";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: false });
          this.setState({ prodUp: false });
          this.setState({ procUp: false });
          this.setState({ partUp: false });
          this.setState({ contUp: true });
          break;
  
        case "#home":
          window.location.hash = "home";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: false });
          this.setState({ prodUp: false });
          this.setState({ procUp: false });
          this.setState({ partUp: false });
          this.setState({ contUp: false });
          break;
  
        case "#proc":
          window.location.hash = "proc";
          this.setState({ GTBUp: false });
          this.setState({ quiUp: false });
          this.setState({ prodUp: false });
          this.setState({ procUp: true });
          this.setState({ partUp: false });
          this.setState({ contUp: false });
          break;
  
  
        default: break;
      
      }
  }

 

  update = () => {
    this.setState({ time: this.state.time + 100 });
    if (this.state.time >= 2300) 
    {
      this.setState({ time: 0});
      this.windowsTreated = false;
      this.firstLoop = false;
    }
    // console.log("this.state.time : ", this.state.time);
    this.globalLightManager();
  }

  globalLightManager()
  {
      var time = this.state.time;

      if (time < 300)
      {
        $(".gradient-bg").css("background-color", "black");

        // this.firstLoop ?
        //   $(".filtBright").css("filter","brightness(10%)") :
        $(".filtBright").css("filter","brightness(5%)") ;
        
        $(".filtBright2").css("filter","brightness(20%)");

      }
      else if (time >= 300 && time < 500)
      {
        $(".gradient-bg").css("background-color", "darkblue");

        // this.firstLoop ?
        //   $(".filtBright").css("filter","brightness(20%") :
          $(".filtBright").css("filter","brightness(5%");

        $(".nightSky").css("opacity","0");
      }
      else if (time >= 500 && time < 700)
      {
        if (time === 500)
          this.activateSignal();
        
        if (time === 600)
        {
          this.receiveSignal();
        }

        $(".buildingWindow").css("background-color","rgba(255, 0, 0, 0.1)");
        $(".gradient-bg").css("background-color","orange");


      }
      else if (time >=700 && time < 1000)
      {

        $(".buildingWindow").css("transition-delay", "0s");
        $(".buildingWindow").css("transition-duration", "4s");
        $(".filtBright").css("filter","brightness(100%)");
        $(".filtBright2").css("filter","brightness(100%)");
        $(".lamp").addClass("off");
        $(".gradient-bg").css("background-color","skyblue");
        
        this.deactivateRooftops();
      }
      else if (time >= 1000 && time <1600)
      {
        $(".buildingWindow").css("background-color", "rgba(255, 255, 255, 0.2)");
        if (time === 1200)
        {
          this.activateSignal();
        }
        if (time === 1300)
        {
          this.receiveSignal();
        }
        if (time === 1400)
        {
          this.activateRooftop("cold");
        }
      }
      else if (time >=1600 && time <1800)
      {
        $(".gradient-bg").css("background-color","orange");
        $(".buildingWindow").css("background-color","rgba(255, 0, 0, 0.1)");
        $(".filtBright").css("filter","brightness(5%");
        $(".filtBright2").css("filter","brightness(20%)");
        if (time === 1600)
        {
          this.activateSignal();
        }
        if (time === 1700)
        {
          this.receiveSignal();
        }
      }
      else if (time >= 1800 && time <2000)
      {
        if (time === 1800)
        {
          this.deactivateRooftops();
        }

        $(".lamp").removeClass("off");
        $(".gradient-bg").css("background-color","darkblue");    
        
        if (!this.windowsTreated)
        {
          for (let i = 0; i < 2000; i++)
          {
            let r = Math.random();
            if (r > 0.5)
            {
              let td = Math.floor(Math.random()*4000)+"ms";
              let rand = "rgb("+Math.floor(Math.random()*255)+","+Math.floor(Math.random()*255)+","+Math.floor(Math.random()*255)+")";
              $(".bw"+i).css("transition-delay", td);
              $(".bw"+i).css("transition-duration", "0s");
              $(".bw"+i).css("background-color", rand);
            }
          }
          this.windowsTreated = true;
        }
      }
      else if (time >= 2000)
      {
        $(".gradient-bg").css("background-color","black");
        $(".nightSky").css("opacity","1");

        if (time === 2000)
        {
          this.activateSignal()
        }
        if (time === 2100)
        {
          this.receiveSignal()
        }
        if (time === 2200)
        {
          this.activateRooftop("hot");
        }
      }

      this.astraRotation +=1/24;
      $(".sunMoonContainer").css("transform", "rotate("+this.astraRotation+"turn)");
  }

  activateSignal()
  {
    $(".curve1.ieh").addClass("fade");
    $(".curve2.ieh").addClass("fade");
    $(".curve3.ieh").addClass("fade");
    $(".curve1.ieh").one("animationend", function(){$(".curve1.ieh").removeClass("fade")});
    $(".curve2.ieh").one("animationend", function(){$(".curve2.ieh").removeClass("fade")});
    $(".curve3.ieh").one("animationend", function(){$(".curve3.ieh").removeClass("fade")});
  }

  receiveSignal()
  {
    $(".curve1.ert").addClass("fade");
    $(".curve2.ert").addClass("fade");
    $(".curve3.ert").addClass("fade");
    $(".curve1.ert").one("animationend", function(){$(".curve1.ert").removeClass("fade")});
    $(".curve2.ert").one("animationend", function(){$(".curve2.ert").removeClass("fade")});
    $(".curve3.ert").one("animationend", function(){$(".curve3.ert").removeClass("fade")});
  }

  activateRooftop(mode)
  {
    $(".ceiling-fan").addClass("spin");
    if (mode === "hot")
    {
      $(".ledHot").addClass("hot").addClass("blinker");
    }
    else
    {
      $(".ledCold").addClass("cold").addClass("blinker");
    }
  }

  deactivateRooftops()
  {
    $(".ceiling-fan").removeClass("spin");
    $(".ledHot").removeClass("hot").removeClass("blinker");
    $(".ledCold").removeClass("cold").removeClass("blinker");
  }

  pageManager = (page) => {

    switch (page) 
    {
      case "gtb":
        window.location.hash = "gtb";
        this.setState({ GTBUp: true });
        this.setState({ quiUp: false });
        this.setState({ prodUp: false });
        this.setState({ procUp: false });
        this.setState({ partUp: false });
        this.setState({ contUp: false });
        break;

      case "qui":
        window.location.hash = "qui";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: true });
        this.setState({ prodUp: false });
        this.setState({ procUp: false });
        this.setState({ partUp: false });
        this.setState({ contUp: false });
        break;

      case "prod":
        window.location.hash = "prod";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: false });
        this.setState({ prodUp: true });
        this.setState({ procUp: false });
        this.setState({ partUp: false });
        this.setState({ contUp: false });
        break;

      case "part":
        window.location.hash = "part";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: false });
        this.setState({ prodUp: false });
        this.setState({ procUp: false });
        this.setState({ partUp: true });
        this.setState({ contUp: false });
        break;

      case "cont":
        window.location.hash = "cont";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: false });
        this.setState({ prodUp: false });
        this.setState({ procUp: false });
        this.setState({ partUp: false });
        this.setState({ contUp: true });
        break;

      case "home":
        window.location.hash = "home";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: false });
        this.setState({ prodUp: false });
        this.setState({ procUp: false });
        this.setState({ partUp: false });
        this.setState({ contUp: false });
        break;

      case "proc":
        window.location.hash = "proc";
        this.setState({ GTBUp: false });
        this.setState({ quiUp: false });
        this.setState({ prodUp: false });
        this.setState({ procUp: true });
        this.setState({ partUp: false });
        this.setState({ contUp: false });
        break;


      default: break;
    }
  }

  getTime = () => {
    return this.state.time;
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getWindowSize() {
    let w = [];
    w[0] = window.innerWidth;
    w[1] = window.innerHeight;
    return w;
  }

  close = () =>
  {
      this.setState({procUp : false});
      this.setState({prodUp: true});
  }

  render() {
    //   console.log("this.state.width : ", this.state.width);
    // console.log("this.state.height : ", this.state.height);

    return (
      
      <div className="appContainer">

            <div className="background">
              <Background/>
              <div className={"environment"}>
                <Sky/>
                <Ground />
                <SunMoon />
                <div className="groundLevel">
                  <ElsysOffice/>
                  <Forest/>
                  <City/>
                </div>
              </div>
            </div>
            <div className="headerContainer">
              <Header width={this.state.width} height={this.state.height} pageManager={this.pageManager}
              />
            </div>
            <div className="mainContent">
                  <GTB isActive={this.state.GTBUp} />
                  <Qui isActive={this.state.quiUp}/>
              <Produits isActive={this.state.prodUp} contentPage={this.state.procUp} pageManager ={this.pageManager}/>
              <SkyColab MenuIsActive={this.state.partUp}/>
              <Contact
                isActive={this.state.contUp} />
            </div>
            <div className="footerContainer">

            </div>
        </div>
    );
  }
}
