import React from 'react';
import "../content.css";

export default function Locate()
 {
        return (
            <div className="formbox">
                <h1 id="c">NOUS CONTACTER</h1>
                <h3 id="c">
                    ZA le Roucagnier - 339, rue du Roucagnier 34400 LUNEL VIEL
                </h3>
                <h1 id="c" style={{color:"white"}}>
                    04 99 53 30 30
                </h1>
                <h1 id="c" style={{color:"gold"}}>
                    <a href="mailto:contact@el-sys.fr">contact@el-sys.fr</a>
                </h1>
            </div>
        )
}