import React from "react";
import './SunMoon.css';

export default class SunMoon extends React.Component
{
    constructor(props)
    {
        super(props);

        const initialRotate = 6/24 ;
        this.state=
        {
            time : this.props.time,
            transform : "rotate("+initialRotate+"turn)",
        }

    }
    rotateInterval = 0;
    rotateDeg = 0;

    componentDidMount()
    {
    }

    componentWillUnmount()
    {
        clearInterval(this.rotateInterval);
    }

    render()
    {
        return (
            <div className="astra">
                <div className="sunMoonContainer" 
                style={{
                    transform:this.state.transform,
                    }}>
                    <div className="moon"></div>
                    <div className="sun"></div>
                </div>
            </div>
        )
    }
}
