import React from "react";
import icon from "../../../images/pc.png";

export default class Prod extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            name : this.props.name,
            image : this.props.image,
            onClick : this.props.onClick,
        }
        
    }

    render()
    {
        return(
            <div className="prod minibox" onClick = {() => this.state.onClick()}>
                <div className="textBox">
                    <h1>{this.props.name}</h1>
                </div>
                <div className="miniboxImgContainer" style={{backgroundImage:`url(${icon})`, backgroundRepeat:"no-repeat", backgroundSize:"contain", backgroundPosition:"center"}}>
                    <img className = "miniboxIconImg" src={this.props.image} alt="Prévisualisation du produit GTB/GTC"/>
                </div>
            </div>
        )
    }
}