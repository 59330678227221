import React from "react";
import Cloud from "./cloud";
import getRandomInt from "../../../script/Random";

/**
 * La classe Ciel s'occupe de générer les nuages.
 */

export default class Sky extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            time : this.props.time,
        }
        this.createClouds(25);
    }

    clouds = [];

    createClouds = (nbr) => {
        // console.log("SKY - Create Clouds () State : " + this.state.isPlanetRunning)
        for (let i = 0; i < nbr; i++) {
            let w = getRandomInt(20, 100);
            let t = getRandomInt(0, 200);
            // let l = randomFunc(0, 300);
            this.clouds.push(
                <Cloud
                    key={i}
                    width={w}
                    height={w}
                    top={t}
                    time={this.state.time}
                />
            )
        }
        return this.clouds;
    }

    render() {
        const className = "clouds active filtBright";
        return (
            <div className={className}>
                {this.clouds}
            </div>
        )
    }
}