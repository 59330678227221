import React from "react";


export default class Tree extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            scale : this.props.scale,
            time : this.props.time,
            color1: "black",
            color2: "black",
            color3: "black",
            color4: "black",
        }
        this.initColor();
    }

    colorArray = [];

    initColor()
    {
            let r = Math.floor(Math.random()*50);
            let g = Math.floor(Math.random()*100+50);
            let b = Math.floor(Math.random()*50);
            let color = "rgb("+r+","+g+","+b+")";
            this.colorArray.push(color);
            
            r+=10;
            g+=10;
            b+=10;
            color = "rgb("+r+","+g+","+b+")";
            this.colorArray.push(color);

            r+=10;
            g+=10;
            b+=10;
            color = "rgb("+r+","+g+","+b+")";
            this.colorArray.push(color);
    }

    colorManagerInterval = 0;

    componentDidMount()
    {
        // this.colorManagerInterval = setInterval(this.colorManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.colorManagerInterval);
    }

    colorManager = () =>
    {
        let t = this.props.time();

        if (t < 800 || t > 1800)
        {
            this.setState({color1 : "black"});
            this.setState({color2 : "black"});
            this.setState({color3 : "black"});
            this.setState({color4 : "black"});
        }
        else
        {
            this.setState({color1: this.colorArray[2]});
            this.setState({color2: this.colorArray[1]});
            this.setState({color3: this.colorArray[0]});
            this.setState({color4: "#502400"});
        }
    }

    render() {
        return(
        <div className={"tree"} style={{
            transform: "scale("+this.state.scale+")",
            
        }}>
            <div className="trunk" style={{background: "#502400"}}></div>
            <div className="tree-circle1" style={{background:this.colorArray[2]}}></div>
            <div className="tree-circle2" style={{background:this.colorArray[1]}}></div>
            <div className="tree-circle3" style={{background:this.colorArray[0]}}></div>
        </div>
        )
    }
}