import React from "react";
import BuildingWindow from "./BuildingWindow";
import BuildingPart from "./BuildingPart";
import Rooftop from "../roofTop/roofTop";
import Y from "./elsys/y";
// import NightWindow from "./NightWindow";

export default class Building extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            time : this.props.time,
            filter1 : "brightness(0%)",
            filter2 : "none",
            calendar : [700, 900, 1200, 1700, 2200],
        }

        this.generateBuildingColors();
        this.createBuilding();
        this.createWindows();

    }

    nbrOfBuildings = 5;
    maxNbrWindows = 50;
    windowArray = [];
    nightWindowArray = [];
    buildingDivArray = [];
    buildingDimensions = [];
    lightInterval = 0;

    componentDidMount()
    {
        // this.lightInterval = setInterval(this.lightManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.lightInterval);
    }

    lightManager = () =>
    {
        let t = this.state.time();

        if (t < 800 || t > 1700)
        {
            this.setState({filter1 : "brightness(50%)"});
        }
        else
        {
            this.setState({filter1 : "brightness(100%)"})
        }
    }
    
    createBuilding()
    {

        let width = Math.floor(Math.random()*15+30)*2;
        let height = Math.floor(Math.random()*105+75)*2;
        this.buildingDimensions[0] = width;
        this.buildingDimensions[1] = height;
    }
    
    createWindows()
    {
        this.maxNbrWindows = (this.getBuildingDimensions()[0]*this.getBuildingDimensions()[1])/32;
        for (let i = 0; i < this.maxNbrWindows; i++)
        {
            this.windowArray.push(<BuildingWindow key={i} color = {"black"} time = {this.state.time} id = {i}/>);
        }
    }

    generateBuildingColors()
    {
        for (let i = 0; i < this.nbrOfBuildings; i++)
        {
            let col1 = Math.floor(Math.random()*155+100);
            let col2 = Math.floor(Math.random()*155+100);
            let col3 = Math.floor(Math.random()*155+100);

            this.buildingDivArray.push(col1);
            this.buildingDivArray.push(col2);
            this.buildingDivArray.push(col3);
        }
    }

    getBuildingDimensions()
    {
        return this.buildingDimensions;
    }

    render()
    {
        return(
            <div className="building" style =
            {{
                width : this.buildingDimensions[0]+"px",
                height: this.buildingDimensions[1]+"px",
            }}>
                <div className = "buildingTop" style={{}}>
                        <Rooftop time = {this.state.time}/>
                        <Y time = {this.state.time} id = "ert" animDelay = {2000} calendar = {this.state.calendar}/>
                </div>
                <div className = "buildingColors">
                            <BuildingPart color = {this.buildingDivArray[0]} time = {this.state.time}/>
                            <BuildingPart color = {this.buildingDivArray[1]} time = {this.state.time}/>
                            <BuildingPart color = {this.buildingDivArray[2]} time = {this.state.time}/>
                </div>
                <div className = "windows">
                    {this.windowArray}
                </div>
            </div>
        )
    }
}