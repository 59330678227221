import React from "react";

export default class Hill extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            color : this.savedColor,
            height: this.props.height,
            time : this.props.time,
            left : this.props.left,
        }
    
    }

    colorManagerInterval = 0;
    savedColor = this.props.color;

    componentDidMount()
    {
        // this.colorManagerInterval = setInterval(this.colorManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.colorManagerInterval);
    }

    colorManager = () =>
    {
        let t = this.props.time();

        if (t < 800 || t > 1800)
        {
            this.setState({color : "black"})
        }
        else
        {
            this.setState({color : this.savedColor});
        }
    }
    render()
    {
        return(
            <ellipse className = "hill filtBright" cx={this.state.left} cy ="100%" rx ="33%" ry={this.state.height} stroke={"none"} fill={this.state.color} />
        )
    }
}