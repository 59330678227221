import React from "react";
import close from "../../../images/closeBttns.png";
import buro1 from "../../../images/prods/buro1.png";
import buro2 from "../../../images/prods/buro2.png";
import stat1 from "../../../images/prods/stat1.png";
import stat2 from "../../../images/prods/stat2.png";
import hote1 from "../../../images/prods/hote1.png";
import hote2 from "../../../images/prods/hote2.png";
import gale1 from "../../../images/prods/galeries.png";
import gale2 from "../../../images/prods/gale2.png";
import gale3 from "../../../images/prods/gale3.png";
import surf1 from "../../../images/prods/surfaces.png";
import surf2 from "../../../images/prods/surf2.jpg";
import mult1 from "../../../images/prods/mult1.png";
import mult2 from "../../../images/prods/mult2.png";
import supe1 from "../../../images/prods/frigo.png";
import supe2 from "../../../images/prods/supe2.png";
import danf1 from "../../../images/prods/danfoss.png";
import danf2 from "../../../images/prods/danf2.jpg";



export default class ProdContent extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            isActive : this.props.isActive,
            activePage : this.props.activePage,
            close : this.props.close,
        }
    }


    loadManager(page){
        let ret = [];
        switch (page) {
            case "buro":
                ret = [];
                ret.push("buro");
                ret.push("Immeuble de bureaux");
                ret.push(buro1);
                ret.push(<p>Elsys Solutions a mis au point une GTB/GTC <b>standardisée</b> pour un déploiement rapide sur vos <b>immeubles de bureaux</b>. Mise au point avec le logiciel <b>Niagara 4</b> de Tridium, elle permet comme les autres solutions une <b>supervision multi-protocole et évolutive</b>, avec une prise en main simple grâce à une interface graphique web consultable par internet <b>depuis n'importe quel navigateur web.</b></p>);
                ret.push(<>
                <p>La GTB permet, entre autres, de :</p> 
                <ul>
                    <li>
                     Piloter l'éclairage, et autres équipements électriques ;
                    </li>
                    <li>
                     Piloter les équipements de CVC secteur par secteur, pour garder un contrôle de la température spécifique pour les locaux techniques (serveurs) ;
                    </li>
                    <li>
                     Acquérir des données de comptage, sous-comptage et d'analyse de données grâce à des courbes et graphiques ;
                    </li>
                </ul>    
                <p>L'interface étant évolutive et personnalisable, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins. Nous proposons également une documentation et une assistance dans le cadre d'une mise en service de la solution par vos techniciens. Cette solutions vous intéresse? Contactez-nous !</p>
                </>);
                
                ret.push(buro2);
                break;

            case "stat":
                ret = [];
                ret.push("stat");
                ret.push("Station service");
                ret.push(stat1);
                ret.push(<>	
                    <p><b>Elsys Solutions</b> a mis au point une <b>GTB/GTC standardisée</b> pour un déploiement rapide sur vos stations services.
                    Mise au point avec le logiciel Niagara 4 de Tridium, elle permet comme les autres solutions une <b>supervision multi-protocole et évolutive</b>, avec une prise en main <b>simple</b> grâce à une interface graphique web consultable par internet depuis n'importe quel navigateur web.</p></>);
                ret.push(<>
                <p>La GTB permet, entre autres, de :</p>
                <ul>
                    <li>
                        Piloter l'éclairage, et autres équipements électriques ;
                    </li>
                    <li>
                        Piloter les équipements de <b>CVC et vos chaînes de froid positives et négatives</b> ;
                    </li>
                    <li>
                        Acquérir des <b>données de comptage sur tous vos équipements et services</b>, sous-comptage et d'<b>analyse de données</b> grâce à des <b>courbes et graphiques</b> ;
                    </li>
                </ul>
                <>
                <p>L'interface étant <b>évolutive et personnalisable</b>, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins.</p><br></br>
                <p>Nous proposons également une <b>documentation et une assistance</b> dans le cadre d'une mise en service de la solution par vos techniciens.</p>
                </>
                
                </>);
                ret.push(stat2);
                break;

            case "hote":
                ret = [];
                ret.push("hote");
                ret.push("Hôtellerie");
                ret.push(hote1);
                ret.push(<>	
                    <p><b>Elsys Solutions</b> a mis au point une interface <b>GTB/GTC complète</b> et ergonomique à destination de l'hôtellerie.
                    La GTB a été développée sur le logiciel Niagara 4 de Tridium, qui propose une solution <b>multi-protocole et évolutive</b>, avec une prise en main simple grâce à une interface graphique web consultable par internet depuis n'importe quel navigateur web (Chrome, Firefox, Edge, ...).
                    Plusieurs supports matériels peuvent être utilisés tels que le Jace8000 ou le EGDE10 de Tridium,  l'ILC2050 de Phoenix Contact ou encore l'iSMA-B-MAC36NL de GC5.</p></>);
                ret.push(<>
                <p>La GTB pour l'hôtellerie développée par Elsys Solutions propose :</p>
                <ul>
                    <li>
                        La visualisation rapide sur <b>plans 3D</b> de l'hôtel et des étages de l'état de la CVC et de la température des chambres. Sur l'exemple, un code couleur permet de comparer la température d'une chambre par rapport à la moyenne des températures de l'hôtel.
                    </li>
                    <li>
                        Des informations détaillées pour chaque chambre de l'hôtel. En installant des <b>régulateurs autonomes</b> dans chaque chambre de l'hôtel, les données de températures, fonctionnement de la CVC, état d'occupation de la chambre et ouverture/fermeture de la fenêtre sont transmises à la GTB. Toutes ces données sont visibles facilement et permettent d'identifier rapidement et à distance d'éventuels 
                        dysfonctionnements.
                    </li>
                    <li>
                        Un « reset » quotidien des consignes de températures des chambres selon une valeur flottante (calculée par rapport à la température extérieure) ou définie.
                    </li>
                    <li>
                        La gestion complète d'équipements de CVC (CTA) et de systèmes de chauffage/clim (chaufferie, groupes d'eau glycolée);
                    </li>
                    <li>
                        <b>Des consoles d'alarmes</b> permettent la gestion des avalanches d'alarmes. Une ligne synthétise les alarmes multiples d'un même point et permet d'accéder aux paramètres de l'équipement d'un seul clic.
                    </li>
                    <li>
                        <b>L'historisation</b> des données ;
                    </li>
                    <li>
                        Des <b>logs complets</b> de connexions et de modifications des paramètres ;
                    </li>
                </ul>
                <>
                <p>L'interface étant <b>évolutive et personnalisable</b>, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins.</p><br></br>
                <p>Nous proposons également une <b>documentation et une assistance</b> dans le cadre d'une mise en service de la solution par vos techniciens.</p>
                </>
                
                </>);
                ret.push(hote2);
                break;

                case "gale":
                    ret = [];
                    ret.push("gale");
                    ret.push("Galeries Marchandes");
                    ret.push(gale1);
                    ret.push(<div>	
                        <p><b>Elsys Solutions</b> a mis au point une interface <b>GTB/GTC complète</b> et ergonomique à destination des galeries marchandes et grandes surfaces commerciales.
                        La GTB a été développée sur le logiciel Niagara 4 de Tridium, qui propose une solution <b>multi-protocole et évolutive</b>, avec une prise en main simple grâce à une interface graphique web consultable par internet depuis n'importe quel navigateur web (Chrome, Firefox, Edge, ...).
                        Plusieurs supports matériels peuvent être utilisés tels que le Jace8000 ou le EGDE10 de Tridium,  l'ILC2050 de Phoenix Contact ou encore l'iSMA-B-MAC36NL de GC5.</p></div>);
                    ret.push(<>
                    <p>La GTB pour galeries marchandes par Elsys Solutions propose :</p>
                    <ul>
                        <li>
                            La visualisation rapide sur <b>plans 3D</b> de l'état et les informations des <b>éclairages</b>, de la CVC, des compteurs d'eau ou électriques ;           </li>
                        <li>
                            La gestion complète d'équipements de CVC tels que les rooftops avec aperçu en temps réel du fonctionnement de l'équipement grâce à une animation 3D sur-mesure ; 
                            <br></br><img src={gale3} style={{width:"200px", float:"right"}} alt=""/>                       
                        </li>
                        <li>
                            L'acquisition des données de <b>comptage</b> et sous-comptage avec <b>analyses de données de consommations</b>. Les rapports sont affichées sous forme de courbes et graphiques qui peuvent être édités et exportés par les utilisateurs ;                        </li>
                        <li>
                            La gestion complète d'équipements de CVC (CTA) et de systèmes de chauffage/clim (chaufferie, groupes d'eau glycolée);
                        </li>
                        <li>
                            <b>Des consoles d'alarmes</b> permettent la gestion des avalanches d'alarmes. Une ligne synthétise les alarmes multiples d'un même point et permet d'accéder aux paramètres de l'équipement d'un seul clic.
                        </li>
                        <li>
                            <b>L'historisation</b> des données ;
                        </li>
                        <li>
                            La gestion d'interactions de sorties par rapports a l'état d'entrées sous forme de condition ou de forçage ;
                        </li>
                        <li>
                            Des <b>logs complets</b> de connexions et de modifications des paramètres ;
                        </li>
                    </ul>
                    <>
                    <p>L'interface étant <b>évolutive et personnalisable</b>, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins.</p><br></br>
                    <p>Nous proposons également une <b>documentation et une assistance</b> dans le cadre d'une mise en service de la solution par vos techniciens.</p>
                    </>
                    
                    </>);
                    ret.push(gale2);
                    break;

                case "surf":
                    ret = [];
                    ret.push("surf");
                    ret.push("Surfaces Commerciales");
                    ret.push(surf1);
                    ret.push(<>	
                        <p><b>Elsys Solutions</b> a mis au point une interface<b>GTB/GTC complète</b>et ergonomique à destination des galeries marchandes et grandes surfaces commerciales.
                        La GTB a été développée sur le logiciel Niagara 4 de Tridium, qui propose une solution <b>multi-protocole et évolutive</b>, avec une prise en main simple grâce à une interface graphique web consultable par internet depuis n'importe quel navigateur web (Chrome, Firefox, Edge, ...).
                        Plusieurs supports matériels peuvent être utilisés tels que le Jace8000 ou le EGDE10 de Tridium,  l'ILC2050 de Phoenix Contact ou encore l'iSMA-B-MAC36NL de GC5.</p></>);
                    ret.push(<>
                    <p>La GTB pour galeries marchandes par Elsys Solutions propose :</p>
                    <ul>
                        <li>
                            La visualisation rapide sur <b>plans 3D</b> de l'état et les informations des <b>éclairages</b>, de la CVC, des compteurs d'eau ou électriques ;           </li>
                        <li>
                            La gestion complète d'équipements de CVC tels que les rooftops avec aperçu en temps réel du fonctionnement de l'équipement grâce à une animation 3D sur-mesure ; 
                            <br></br><img src={gale3} style={{width:"200px", float:"right", paddingLeft:"10px"}} alt=""/>                       
                        </li>
                        <li>
                            L'acquisition des données de <b>comptage</b> et sous-comptage avec <b>analyses de données de consommations</b>. Les rapports sont affichées sous forme de courbes et graphiques qui peuvent être édités et exportés par les utilisateurs ;                        </li>
                        <li>
                            La gestion complète d'équipements de CVC (CTA) et de systèmes de chauffage/clim (chaufferie, groupes d'eau glycolée);
                        </li>
                        <li>
                            <b>Des consoles d'alarmes</b> permettent la gestion des avalanches d'alarmes. Une ligne synthétise les alarmes multiples d'un même point et permet d'accéder aux paramètres de l'équipement d'un seul clic.
                        </li>
                        <li>
                            <b>L'historisation</b> des données ;
                        </li>
                        <li>
                            La gestion d'interactions de sorties par rapports a l'état d'entrées sous forme de condition ou de forçage ;
                        </li>
                        <li>
                            Des <b>logs complets</b> de connexions et de modifications des paramètres ;
                        </li>
                    </ul>
                    <>
                    <p>L'interface étant <b>évolutive et personnalisable</b>, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins.</p><br></br>
                    <p>Nous proposons également une <b>documentation et une assistance</b> dans le cadre d'une mise en service de la solution par vos techniciens.</p>
                    </>
                    
                    </>);
                    ret.push(surf2);
                    break;

                case "mult":
                    ret = [];
                    ret.push("mult");
                    ret.push("Multi-services");
                    ret.push(mult1);
                    ret.push(<>	
                        <p>Pour les GTC / GTB sur-mesures de moyenne et grande envergure, nous utilisons le logiciel de GTB Tridium <b>Niagara</b>.
                        Niagara est un logiciel de nouvelle génération totalement sécurisé qui peut être utilisé sur un automate ou sur PC (Windows et Linux).
                        Ses atouts sont :</p>
                        <ul>
                            <li>
                                Le Serveur Web embarqué en HTML5 et Javascript sans contraintes de Java ou de Flash.
                            </li>
                            <li>
                                Le <b>multi-protocole</b> Modbus, Bacnet, LONWORKS, KNX, ZWAVE d'origine ainsi que plusieurs dizaines de protocoles propriétaire qui peuvent venir compléter le système (Dali, Trend, Cbus, Danfoss…).
                            </li>
                            <li>
                                Les web-services comme OBIX et JSON.
                            </li>
                            <li>
                                L'évolution de 100 à plus de 10 000 points de communication permet de correspondre au mieux à votre projet tout en gardant toujours la même puissance matérielle et logicielle.
                            </li>
                        </ul>
                        
                        </>);
                    ret.push(<>
                    <ul>
                        <li>
                            Le protocole de communication du logiciel permet l'interconnexion entre un superviseur et plusieurs automates de façon sécurisé. Les données d'historiques, d'utilisateurs, d'alarmes, d'états et de valeurs sont synchronisées pour fournir une visualisation en temps réel.   
                        </li>
                    </ul>
                        <p>L'automate Jace 8000 comprend :</p>
                    <ul>
                        <li>
                            2 ports TCP sur 2 cartes réseau distinctes ;
                        </li>
                        <li>
                            2 ports RS485 de base ;
                        </li>
                        <li>
                            jusqu'à 3 cartes additionnelles RS485, LON FTT10 et RS232.
                        </li>
                    </ul>
                    </>
                    );
                    ret.push(mult2);
                    break;

                case "supe":
                    ret = [];
                    ret.push("supe");
                    ret.push("Supervision Frigorifique");
                    ret.push(supe1);
                    ret.push(<>	
                        <p><b>Elsys Solutions</b> a mis au point une interface<b>GTB/GTC complète</b>et ergonomique à destination de la supervision de meubles frigorifiques autonomes.
                        La GTB a été développée sur le logiciel Niagara 4 de Tridium, qui propose une solution <b>multi-protocole et évolutive</b>, avec une prise en main simple grâce à une interface graphique web consultable par internet depuis n'importe quel navigateur web (Chrome, Firefox, Edge, ...).
                        Pour nos sondes, nous utilisons la technologie sans fil testée et éprouvée depuis plusieurs années sur des sites tests.
                        Ses atouts sont :</p>
                        <ul>
                            <li>
                                Facilité d'installation et gain de temps dû à la <b>technologie sans fil</b>. La portée des capteurs peut aller de quelques centaines de mètres à plusieurs kilomètres.
                            </li>
                            <li>
                                <b>Adaptabilité</b> de la GTC pouvant aller de quelques sondes à plusieurs dizaines.
                            </li>
                        </ul>
                        
                        </>);
                    ret.push(<>
                    <ul>
                        <li>
                            Traçabilité de visualisation de l'état du parc par le serveur Web embarqué depuis n'importe quel navigateur ;
                        </li>
                        <li>
                            Renvoi <b>d'alarmes</b> pour alamristes par contact sec et/ou par mail, téléphone ...
                        </li>
                        <li>
                            <b>Visualisation des défauts</b> sur un écran en local.
                        </li>
                        <li>
                            <b>Export des données</b> de températures journalières aux normes <b>HACCP.</b> 
                        </li>
                    </ul>
                    <p>L'interface étant <b>évolutive et personnalisable</b>, notre équipe d'experts est à votre disposition pour adapter la GTB à vos besoins.</p><br></br>
                    <p>Nous proposons également une <b>documentation et une assistance</b> dans le cadre d'une mise en service de la solution par vos techniciens.</p>
                    </>
                    );
                    ret.push(supe2);
                    break;
                    
                case "danf":
                    ret = [];
                    ret.push("danf");
                    ret.push("Danfoss Multi-métiers");
                    ret.push(danf1);
                    ret.push(<>	
                        <p><b>Danfoss</b> est une marque reconnue dans le milieu technique. Elle fournit depuis plusieurs années maintenant des <b>systèmes de surveillance et de pilotage</b>.<br></br>
                            L'AKSM-800 est une GTC avec écran et ports de communication intégrés ce qui la rend autonome.
                            La communication se fait facilement avec tous les régulateurs et autres équipement <b>Danfoss</b>.<br></br>
                            Cette GTC est parfaite pour les surfaces commerciales car elle gère toute la gestion du froid, l'éclairage et la CVC.
                        </p>           
                        </>);
                    ret.push(<>
                    <p>Les atouts :</p>
                    <ul>
                        <li>
                            <b>Rapidité de mise en service</b> due à la standardisation qui répond parfaitement aux attentes des surfaces commerciales.
                        </li>
                        <li>
                             Multi-métier, ce qui permet de centraliser les données du bâtiment : froid, éclairage, CVC, comptage ...
                        </li>
                        <li>
                            Affichage sur écran pour des modifications en local et serveur web intégré pour <b>accès à distance</b>.
                        </li>
                        <li>
                            Communication avec les équipements Danfoss facilitée, un simple scan permet d'afficher directement toutes les valeurs. 
                        </li>
                        <li>
                        Apprécié et connu par les techniciens du froid.
                        </li>
                        <li>
                        Affichage de synoptique sur mesure pour situer les données.
                        </li>
                        <li>
                        Connexion en web-service possible grâce à une <b>API</b> déjà intégrée dans la plus part des hypervisions.
                        </li>
                    </ul>
                    <p>N'hésitez pas à nous contacter pour nous communiquer vos attentes.</p>
                    </>
                    );
                    ret.push(danf2);
                    break;

                    
                    



            default: break;
        }
        return ret;
    }


    render()
    {
        const disp = this.props.isActive ? "flex" : "none";
        const p = this.props.activePage;

        return(
            <div className ="prodPage" style = {{display : disp}}>
                <div className="prod-bg o">

                    <div className="red column" style={{gridColumnStart:40}}></div>
                    <div className="orange column"style={{gridColumnStart:41}}></div>
                    <div className="gold column"style={{gridColumnStart:42}}></div>
                    <div className="white column" style={{gridColumnStart:43}}></div>
                    <div className="gold column"style={{gridColumnStart:44}}></div>
                    <div className="orange column"style={{gridColumnStart:45}}></div>
                    <div className="red column" style={{gridColumnStart:46}}></div>

                    <div className="red column" style={{gridColumnStart:150}}></div>
                    <div className="orange column"style={{gridColumnStart:151}}></div>
                    <div className="gold column"style={{gridColumnStart:152}}></div>
                    <div className="white column" style={{gridColumnStart:153}}></div>
                    <div className="gold column"style={{gridColumnStart:154}}></div>
                    <div className="orange column"style={{gridColumnStart:155}}></div>
                    <div className="red column" style={{gridColumnStart:156}}></div>

                    <div className="red row" style={{gridRowStart:60}}></div>
                    <div className="orange row"style={{gridRowStart:61}}></div>
                    <div className="gold row"style={{gridRowStart:62}}></div>
                    <div className="white row" style={{gridRowStart:63}}></div>
                    <div className="gold row"style={{gridRowStart:64}}></div>
                    <div className="orange row"style={{gridRowStart:65}}></div>
                    <div className="red row" style={{gridRowStart:66}}></div>

                    <div className="red row" style={{gridRowStart:150}}></div>
                    <div className="orange row"style={{gridRowStart:151}}></div>
                    <div className="gold row"style={{gridRowStart:152}}></div>
                    <div className="white row" style={{gridRowStart:153}}></div>
                    <div className="gold row"style={{gridRowStart:154}}></div>
                    <div className="orange row"style={{gridRowStart:155}}></div>
                    <div className="red row" style={{gridRowStart:156}}></div>
 
                </div>


                <div className="prodPage-top">
                    <h1 className="prodTitle">{this.loadManager(p)[1]}</h1>
                    <div className="x" onClick={() => this.state.close("prod")}>
                        <img alt="close button" src={close}/>
                    </div>

                </div>
                <div className="prodPage-flex">
                    <div className="img1">
                        <img alt="Descriptif de la GTB"src={this.loadManager(p)[2]}/>
                    </div>
                    <div className="txt1">{this.loadManager(p)[3]}</div>
                    <div className="txt2">{this.loadManager(p)[4]}</div>
                    <div className="img2">
                        <img alt="Descriptif de la GTB"src={this.loadManager(p)[5]}/>
                    </div>
                </div>
                <div className="prodPage-footer">
                    
                </div>

            </div>
        )
    }
}