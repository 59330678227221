import React from "react";
import Tree from "./Tree";
import "./Tree.css";

export default class Forest extends React.Component {

    createTree(nb) {
        const tree_tab = [];
        
        for (let i = 0; i < nb; i += 1) {
            var s = Math.random()*50+100;
            tree_tab.push(<Tree
                key={i}
                scale = {s+"%"}
            
            />);
        }
        return tree_tab;
    }

    render() {

        return(
            <div className={"forest filtBright"}>    
                {this.createTree(180)}
            </div>
        )
    }
}

