import React from "react";
import './clouds.css'
import getRandomInt from "../../../script/Random";

/**
 * La classe des nuages, dont l'animation et le déplacement sont générés aléatoirement.
 */

export default class Cloud extends React.Component{
    constructor(props) {
        super(props);
        this.state =
        {
            width: this.props.width,
            height: this.props.height,
            top: this.props.top,
            left: this.props.left,
            time: this.props.time,
            boxShadow : "#ffffff 65px -15px 0 -5px, #ffffff 25px -25px, #ffffff 30px 10px, #ffffff 60px 15px 0 -10px, #ffffff 85px 5px 0 -5px",
            color : "white",
        }

    }

    
   
    componentDidMount()
    {
        // this.lightInterval = setInterval(this.lightManager, 2000);
    }

    componentWillUnmount()
    {
        clearInterval(this.lightInterval);
    }

    lightManager = () =>
    {
        
        if (this.state.time() < 800 || this.state.time() > 1700)
        {
            this.setState({boxShadow : this.darkBoxShadow});
            this.setState({color: "black"});
        }
        else
        {
            this.setState({boxShadow : this.lightBoxShadow});
            this.setState({color: "white"});
        }
    }

    animDuration = getRandomInt(70, 100);
    animDelay = getRandomInt(0, 100);
    lightInterval = 0;
    lightBoxShadow = "#ffffff 65px -15px 0 -5px, #ffffff 25px -25px, #ffffff 30px 10px, #ffffff 60px 15px 0 -10px, #ffffff 85px 5px 0 -5px";
    darkBoxShadow = "#000 65px -15px 0 -5px, #000 25px -25px, #000 30px 10px, #000 60px 15px 0 -10px, #000 85px 5px 0 -5px";


    render() {

        return (
            <div className={"cloud active"} style=
            {{
                width: this.state.width,
                height: this.state.height,
                top: this.state.top,
                left: this.state.left,
                animationDelay: this.animDelay + "s",
                animationDuration: this.animDuration + "s",
                boxShadow : this.state.boxShadow,
                backgroundColor : this.state.color,
            }}>
            </div>
        )
    }
}
