import React from "react";

export default class Star extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            x : this.props.x+"vw",
            y : this.props.y+"vh",
            animDelay : Math.random()*1000 + "ms",
        }
    }

    render()
    {
        return(
            <div className = "star" style={{left : this.state.x, top : this.state.y, animationDelay : this.state.animDelay}}>

            </div>
        )
    }
}