import React from "react";
import "./content.css";
import bureauxImg from "../../images/prods/bureaux.png";
import hotelImg from "../../images/prods/hotel.png";
import stationImg from "../../images/prods/station.png";
import galeriesImg from "../../images/prods/galeries.png";
import surfacesImg from "../../images/prods/surfaces.png";
import multiImg from "../../images/prods/multi.jpg";
import frigoImg from "../../images/prods/frigo.png";
import danfossImg from "../../images/prods/danfoss.png";
import Prod from "./produits/Prod";
import ProdContent from "./produits/ProdContent";


export default class Produits extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            isActive : this.props.isActive,
            contentPage : this.props.contentPage,
            activePage : "none",
            pageManager : this.props.pageManager,
        }      
    }

    onClick = (page) =>
    {
        this.setState({contentPage : true});
        this.state.pageManager("proc");
        
        switch(page)
        {
            case "buro":
                this.setState({activePage : "buro"});
                break;

            case "stat":
                this.setState({activePage : "stat"});
                break;

            case "hote":
                this.setState({activePage : "hote"});
                break;

            case "gale":
                this.setState({activePage : "gale"});
                break;

            case "surf":
                this.setState({activePage : "surf"});
                break;    

            case "mult":
                this.setState({activePage : "mult"});
                break;    

            case "supe":
                this.setState({activePage : "supe"});
                break;
                
            case "danf":
                this.setState({activePage : "danf"});
                break;    

            default: break;    
        }
    }

    // close = () =>
    // {
    //     this.setState({contentPage : false});
    //     this.setState({isActive: true});
    // }

    render()
    {

        const active = this.props.isActive;

        return(
            <>
            <div className = "produits content" style ={{display : active ? "flex" : "none"}}>
                    <Prod name={"Immeuble de bureaux"} image={bureauxImg} onClick={() => this.onClick("buro")}/>
                    <Prod name={"Station service"} image={stationImg} onClick={() => this.onClick("stat")}/>
                    <Prod name={"Hôtellerie"} image={hotelImg} onClick={() => this.onClick("hote")}/>
                    <Prod name={"Galeries marchandes"} image={galeriesImg} onClick={() => this.onClick("gale")}/>
                    <Prod name={"Surfaces commerciales"} image={surfacesImg} onClick={() => this.onClick("surf")}/>
                    <Prod name={"Multi-services"} image={multiImg} onClick={() => this.onClick("mult")}/>
                    <Prod name={"Supervision frigorifique"} image={frigoImg} onClick={() => this.onClick("supe")}/>
                    <Prod name={"Danfoss"} image={danfossImg} onClick={() => this.onClick("danf")}/>
            </div>
                <ProdContent isActive = {this.props.contentPage} activePage = {this.state.activePage} close = {this.state.pageManager}/>
            </>
        )
    }
}