import React from "react";
import conso from "../../images/consoElec.png";
import alarm from "../../images/alarmes.png";
import compt from "../../images/compteurs.png";
import supes from "../../images/supervision.png";
import conso2 from "../../images/consoElec2.png";
import "./content.css";

export default class GTB extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            isActive : this.props.isActive,
        }
        
    }

    render() {
        return(
            <div className = "GTB content" style ={{display : this.props.isActive ? "flex" : "none"}}>
                <div className = "info box">
                    <h1 id="c">La GTB/GTC</h1>
                    <p id="j">
                        <span id="b">La gestion technique de bâtiment (GTB)</span> est un système informatique généralement installé dans des grands bâtiments ou dans des installations 
                        industrielles afin de <span className="b">superviser l’ensemble des équipements qui y sont installés : </span>
                        
                        permettant ainsi une <span id = "b">une surveillance et contrôle efficace </span> de sa consommation d'énergie en vue de <span id ="b">l'optimiser au maximum. </span>
    
                        <br></br><br></br>
                        En France, l’utilisation d’un système d’automatisation et 
                        de contrôle de bâtiment est <span id="b">obligatoire</span> pour tous les bâtiments développant une <span id="b">énergie de plus de 290 kW</span>. Cela correspond à environ <b>3000 m² </b> 
                        dans le cas de bâtiments anciens, à plus de <b>5000 m²</b> pour des bâtiments construits depuis la RT2005 et à plus de <b>6000 m²</b> pour des bâtiments 
                        tertiaires BBC-Effinergie. 
                    </p>
                    <div className = "boxImageContainer">
                        <img className ="boxImage" src={conso} alt="Exemple de supervision GTB comptage"/>
                    </div>
                </div>

                <div className = "objectif box">
                    <h1 id="c">Objectif</h1>
                    <p id="j">
                        Il s’agit de permettre au gestionnaire d’avoir une vue globale du fonctionnement et des automatismes d’un bâtiment ou d’une installation industrielle. <br></br>
    
                            Les données recueillies sont de diverses natures :
                    </p>
                            <ul>
                                <li>
                                    des alarmes (Panne, arrêt anormal, mesure dépassant un seuil…) ;
                                </li>
                                <li>
                                    des états (fonctionnement d’un équipement, position, retour de commande…) ;
                                </li>
                                <li>
                                    des mesures (température, temps de fonctionnement, nombre de pannes…).  
                                </li>
                            </ul>
                            <div className = "boxImageContainer">
                                <img className = "boxImage" src={alarm} alt="Exemple de console d'alarme de GTB"/> 
                            </div>
                </div>
                <div className = "objectif2 box">
                        <h1 id="c">Possibilités</h1>
                            <p style={{alignItems : "left"}}> 
                            Les équipements supervisés peuvent être entre autres :
                            </p>
                                <ul>
                                    <li>
                                    l’alimentation électrique (TGBT, Tableaux divisionnaires) ; l’alimentation de secours (groupes électrogènes , batteries)
                                    </li>
                                    <li>
                                    l’éclairage ;
                                    </li>
                                    <li>
                                    les circulations verticales ;
                                    </li>
                                    <li>
                                    le chauffage, la ventilation et la climatisation (CVC) ;
                                    </li>
                                    <li>
                                    la plomberie (pompes de relevage, cuves…) ;
                                    </li>
                                    <li>
                                    le contrôle d’accès ;  
                                    </li>
                                    <li>
                                    la vidéosurveillance ;   
                                    </li>
                                    <li>
                                    les dispositifs incendie (alarmes, extinction ...) ;
                                    </li>
                                </ul>   
                        <div className = "boxImageContainer">
                            <img className = "boxImage" src={conso2} alt="Exemple de supervision GTB comptage"/>
                        </div>
                </div>
                <div className = "interet box">
                    <h1 id="c">Intérêt</h1>
                    <p id ="j">
                    L’intérêt de la GTB est aussi d’améliorer la performance énergétique du bâtiment en réalisant des économies d’énergies, grâce aux fonctions suivantes : </p>
                    <ul>
                        <li>la régulation du chauffage ;</li>
                        <li>la régulation du refroidissement ;</li>
                        <li>la régulation de la ventilation et de la climatisation ;</li>
                        <li>la commande de l’éclairage ;</li>
                        <li>la commande des stores.</li>
                    </ul>
                    <div className="boxImageContainer">
                        <img className ="boxImage"src={compt} alt="Exemple de supervision GTB électricité"/>

                    </div>
                </div>
                <div className = "architecture box">
                    <h1 id="c">Matériel</h1>
                    <p id="j">
                    Un système de GTB est constitué : </p>
                        <ul>
                            <li>
                                d’un poste informatique de gestion équipé d’un logiciel de supervision (SCADA) ;
                            </li>
                            <li>
                                d’un réseau reliant les « concentrateurs » au poste de gestion ;
                            </li>
                            <li>
                                de plusieurs automates concentrateurs recueillant les informations des équipements au plus près du bâtiment.
                            </li>
                        </ul>
                    <div className="boxImageContainer">
                        <img src={supes} className="boxImage" alt="Structure d'une GTB/GTC"/>
                    </div>
                </div>
            </div>
        )
    }
}