import React from "react";
import Building from "./Building";
import "./City.css";
import Lamp from "./Lamp";

export default class City extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            time: this.props.time,
        }
        this.createBuildings();
        this.createLamps();
    }

    maxNbrBuildings = 4;
    buildingArray = [];
    lampArray = [];
    breakPointArray = [640, 768, 1024, 1280, 1536]

    createBuildings() {
        for (let i = 0; i < this.maxNbrBuildings; i++) {
            this.buildingArray.push(<Building key={i} time={this.state.time} />);
        }
    }

    createLamps() {
        for (let i = 0; i < 5; i++) {
            this.lampArray.push(
                <Lamp
                    key={i}
                    time={this.state.time}
                />
            )
        }
    }

    render() {
        return (


            <div className="city">
                <div className="cityLights">
                    {this.lampArray}
                </div>
                {this.buildingArray}
            </div>
        )
    }
}