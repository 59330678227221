import React from "react";
import LogoY2 from "./y.svg";
import Wireless from "../../wireless/wireless.js";

export default class Y extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            active : "yActive",
            animDelay : this.props.animDelay,
            id : this.props.id,
            transform : this.props.transform,
        }
    }

    render()
    {
        return(
                <div className="wirelessContainer">
                    <Wireless id = {this.state.id} transform = {this.state.transform} />
                    <img id={this.state.id} className="filtBright2" src={LogoY2} alt="logo y de elsys" />
                </div>
        )
    }
}