import React from "react";
import Star from "./Star";

export default class NightSky extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            opacity: 0,
        }
        this.initStars(1000);
    }

    starArray = [];

    initStars(nb) {

        for (let i = 0; i < nb; i++) {
            this.starArray.push (
                    <Star
                        key={i}
                        x={Math.random() * 100}
                        y={Math.random() * 100}
                    />
                )
        }
    }

    render() {
        return (
            <div className="nightSky" style={{ opacity: this.state.opacity }}>
                {this.starArray}
            </div>
        )
    }

}