import React from "react";
import "./wireless.css";

export default class Wireless extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            time : this.props.time,
            transform: this.props.transform,
            animDelay: 0,
            activeClass : "",
            id : this.props.id,
        }
    }


    render()
    {
        return(
            <div className={"wireless"} style={{display:this.state.wirelessDisplay, transform:this.state.transform}}>
                <div className={"curve3 " + this.state.id} style={{animationDelay : this.state.animDelay+400+"ms"}}></div>
                <div className={"curve2 " + this.state.id} style={{animationDelay : this.state.animDelay+200+"ms"}}></div>
                <div className={"curve1 " + this.state.id} style={{animationDelay : this.state.animDelay+"ms"}}></div>
            </div>
        )
    }
}