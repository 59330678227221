import React from "react";
import "./Header.css";
import Logo from "../../images/logo.png";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: this.props.width,
            height: this.props.height,
            pageManager: this.props.pageManager,
            active: true,
        };
        this.textManager = this.textManager.bind(this);
    }

    componentDidMount() {
        this.textManager();
        window.addEventListener("resize", this.textManager);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.textManager);
    }

    textManager() {
        if (this.props.width > 780 && this.state.active === false) {
            this.setState({ active: !this.state.active });
        }
    }

    toggleClass() {
        this.setState({ active: !this.state.active });
    };

    handleClick(str) {
        this.state.pageManager(str);
        this.toggleClass();
    }
    
    goHome(str) {
        this.state.pageManager(str);
        if(this.state.active === false) {
            this.setState({ active: true });
        }
    }

    render() {
        /* On active le mode responsive que si on a cliqué sur
        les 3 barres et que la résolution est inférieure à 780*/

        const bot_header_responsive = !this.state.active && this.props.width < 780 ? "bot-header responsive" : "bot-header";
        const text_responsive = !this.state.active && this.props.width < 780 ? "text-container responsive" : "text-container";
        const button_active = !this.state.active && this.props.width < 780 ? "button active" : "button";

        return (
            <div className="header">
                <div className="top-header">
                    <div className="align-img" onClick={() => this.goHome("home")}>
                        <img src={Logo} alt={"Logo Elsys Solutions, intégrateur GTB/GTC"} />
                    </div>
                    <div className={"container-bars"} onClick={() => this.toggleClass()}>
                        <div className="bars-aligner">
                            <div className={"bar"}></div>
                            <div className={"bar"}></div>
                            <div className={"bar"}></div>
                        </div>
                    </div>
                </div>
                <div className={bot_header_responsive}>
                    <div className={text_responsive}>
                        <div className={button_active} onClick={() => this.handleClick("gtb")}>
                            <p className={"roboto"}>Qu'est ce que la GTB/GTC ?</p>
                        </div>
                        <div className={button_active} onClick={() => this.handleClick("qui")}>
                            <p className={"roboto"}>Qui sommes-nous</p>
                        </div>
                        <div className={button_active} onClick={() => this.handleClick("prod")}>
                            <p className={"roboto"}>Nos Produits</p>
                        </div>
                        <div className={button_active} onClick={() => this.handleClick("part")}>
                            <p className={"roboto"}>Nos Partenaires</p>
                        </div>
                        <div className={button_active} onClick={() => this.handleClick("cont")}>
                            <p className={"roboto"}>Nous Contacter</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}